<template>
  <div class="item">
    <TitleHeader msg="充值"></TitleHeader>
    <el-row class="content">
      <h2 class="text-left">填写充值金额</h2>
      <el-col class="text-left charge"
        ><span>可用余额：</span><span class="num">{{ balace | nums }}</span
        ><span
          ><el-button type="danger" size="small" @click="jumpYpt()"
            >代付宝申请</el-button
          ></span
        ></el-col
      >
      <el-col class="wraper">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
        >
          <el-form-item label="预存款充值" prop="num">
            <el-input v-model="ruleForm.num" clearable  oninput="value=value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')"></el-input> 元
          </el-form-item>
          <el-divider></el-divider>
          <el-form-item prop="resource" label="支付方式">
            <el-radio-group v-model="ruleForm.resource" size="medium">
              <el-col
                :span="8"
                v-for="(item, index) in imgArr"
                :key="index"
                :class="
                  index === imgArrSelect ? 'imgWraper active' : 'imgWraper'
                "
                @click.native="changeFun(item, index)"
              >
                <i class="el-icon-success" aria-hidden="true"></i
                ><img :src="item.img" :alt="item.alt" />
              </el-col>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >确定</el-button
            >
            <el-button @click="goBack">返回</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <!-- 支付二维码弹框 -->
    <el-dialog
      title="扫码充值"
      center
      :visible.sync="DialogShowQRCode"
      width="300px"
      height="300px"
      @close="closeDialog"
    >
      <vue-qr
        :bgSrc="config.logo"
        :logoSrc="config.logo"
        :text="config.value"
        :size="200"
      ></vue-qr>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
import VueQr from "vue-qr";
import Qs from "qs";
import { savePayId, PayIdSave ,MemberInfo,RechargeDetail} from "api/member.js";
const TitleHeader = () => import("components/common/TitleHeader.vue");
export default {
  name: "Recharge",
  data() {
    return {
      DialogShowQRCode: false,
      config: {
        value: "", //显示的值、跳转的地址
        imagePath: require("@/assets/logo.png"), //中间logo的地址
      },
      ruleForm: {
        num: "", //要充值的金额
        resource: 1,
      },
      rules: {
        num: [
          { required: true, message: "请输入要充值的金额", trigger: "blur" },
        ],
        resource: [
          { required: true, message: "请选择支付方式", trigger: "change" },
        ],
      },
      balace: 0, //余额
      imgArr: [
        {
          img:
            "http://image.demo.b2b2c.shopxx.net/6.0/16d9ceaf-ada6-480d-95c3-6244eac4b0ee.png",
          alt: "微信支付",
          num: 1,
        },
        {
          img:
            "http://image.demo.b2b2c.shopxx.net/6.0/aa348738-a710-4330-915d-a2d2475681e6.png",
          alt: "支付宝",
          num: 2,
        },
        // {
        //   img:
        //     "https://3akx.oss-cn-beijing.aliyuncs.com/upload/image/202008/b3bb411d-e3ee-47d4-be26-cb5c101d0b7e.png",
        //   alt: "银联支付",
        // },
      ],
      imgArrSelect: 0,
      IdData:''//支付订单Id
    };
  },
  methods: {
    closeDialog() {
      this.$confirm("请根据您的支付情况点击下面的按钮", "请确认支付是否完成", {
        confirmButtonText: "已完成支付",
        cancelButtonText: "支付遇到问题",
        type: "warning",
      })
        .then(() => {
          // 去判断是否充值成功
            RechargeDetail(this.IdData).then((data)=>{
              if(data.data.data.status==1){//订单为待发货状态
                    // 跳转充值成功页面
                    let datas = {
                      sn: sessionStorage.getItem("orderId"),
                      total: this.total,
                      creatTime: moment(this.creatTime).format("YYYY-MM-DD a hh:mm:ss"),
                    };
                    sessionStorage.setItem("orderDatas", Qs.stringify(datas));
                    this.$router.push({ path: "/my"});

              }else{
                  this.$message({
                    message:'您未充值成功',
                    type: "warning",
                  });
              }
          })
        })
        .catch(() => {
           // 支付不成功，关闭当前弹框，停留当前页面
          // this.$router.push({ path: "/home" });
             // 去判断是否充值成功
              RechargeDetail(this.IdData).then((data)=>{
                if(data.data.data.status==1){//订单为待发货状态
                      // 跳转充值成功页面
                      let datas = {
                        sn: sessionStorage.getItem("orderId"),
                        total: this.total,
                        creatTime: moment(this.creatTime).format("YYYY-MM-DD a hh:mm:ss"),
                      };
                      sessionStorage.setItem("orderDatas", Qs.stringify(datas));
                      this.$router.push({ path: "/my"});

                }else{
                    this.$message({
                      message:'您未充值成功',
                      type: "warning",
                    });
                }
            })
        });
    },
    //   选择支付方式
    changeFun(item, index) {
      this.imgArrSelect = index;
      this.ruleForm.resource = item.num;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let datas = {
            buyChannel: 1,
            money: this.ruleForm.num,
            paymentMethod: this.ruleForm.resource,
          };
          savePayId(datas).then((res) => {
            this.IdData = {
              id: res.data.data.id,
            };
            PayIdSave(this.IdData).then((res) => {
              if (res.data.code == 200) {
                //获取支付链接  生成二维码
                this.DialogShowQRCode = true;
                this.config.value = res.data.data.billQRCode;
              }
            });
          });
        } else {
          return false;
        }
      });
    },
    goBack() {
      this.$router.back();
    },
    jumpYpt() {
      this.$router.push({ name: "Ypt" });
    },
  },
  created() {
     MemberInfo().then((res) => {
          this.balace=res.data.data.balance
     });
  },
  filters: {
    nums(value) {
      return "￥" + value;
    },
  },
  components: {
    TitleHeader,
    VueQr,
  },
};
</script>
<style lang="less" scoped>
@import "~style/index.less";
/deep/.el-dialog__body {
    padding: 0px 25px 10px !important;
    img {
      display: block !important;
      margin: 0 auto;
    }
  }
.item {
  .fonts();
  color: #666;
  .content {
    padding-top: 20px;
    padding-left: 12px;
    h2 {
      color: #333;
      font-size: 16px;
      line-height: 22px;
      font-weight: bold;
    }
    .charge {
      padding-top: 30px;
    }
    .charge span:first-child {
      padding-left: 18px;
    }
    .num {
      font-weight: 600;
      color: @theme;
      padding-right: 30px;
    }
    .el-form {
      width: 100%;
    }
    .el-input {
      width: 110px;
    }
    .wraper {
      display: flex;
      align-items: center;
      .el-form-item {
        padding-top: 20px;
        .imgWraper {
          width: 200px;
          height: 50px;
          border: 1px solid #dddddd;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 3px;
          margin-left: 20px;
          img {
            display: inline-block;
          }
          .el-icon-success {
            color: #cccccc;
            font-size: 28px;
          }
          &.active {
            border: 1px solid @theme;
            .el-icon-success {
              color: @theme;
            }
          }
        }
        .imgWraper:first-child {
          margin-left: 0px;
        }
      }
    }
    /deep/.el-button--primary {
      background-color: @theme;
      border-color: @theme;
      width: 70px;
      height: 40px;
      color: #fff;
      margin-top: 10px;
      padding-top: 10px;
    }
    /deep/.el-button + .el-button {
      margin-left: 30px;
    }
  }
}
</style>
